import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import Caption from '../components/caption';
import NextProj from '../components/nextproject';

import Layout from '../components/layout'
import '../components/layout.css'
import '../components/projectCards.css'


const Beddr = (props) => (
    <Layout>
        <Helmet
        title="Juan Herrera — Beddr"
        meta={[
          { name: 'description', content: 'Juan Herrera — Product designer' },
          { name: 'keywords', content: 'product, design, ui, ux' },
        ]}
        />
      <div className="casestudies">
        <div className="casestudyTop">
            <div className="back"><p><Link to="/">Back</Link></p></div>
            <div className="topcontainer">
                <div className="casestudyintro">
                <h2>Beddr</h2>
                <p>Health startup Beddr created a smart device that aims to aid in the detection of sleep apnea, as well as recommend some potential treatments to users at an affordable price. STRV helped with designing and developing a version of the iOS app to interface with said device.</p>
            </div>
            <div id="CSinfo">
              <div className="projectInfo"><h5>Role</h5>
                  <p>UI/UX Design</p>
                  <h5>Platform</h5>
                  <p>iOS</p>
                  <h5>Year</h5>
                  <p>2017</p>
              </div>
            </div>
          </div>
        </div>
          <div className="desktopImages">
                <Img title="Beddr Hero" fluid={props.data.beddr1.childImageSharp.fluid} />
          </div>
          <Caption captiontext="Beddr — Home screen"/>
        <div className="dividers">Overview</div>
          <div className="casestudyContent">
              <div className="CSleftCol">
                <p>Beddr's smart device is called a Sleep Tuner, and users put one on their forehead before going to sleep. By means of a companion app, a user would get a full report of different bits of data gathered while they sleep, such as how many “low-oxygen” events they went through at night, or what was the longest time they spent with little to no oxygen while asleep.</p>
                </div>
              <div className="CSrightCol">
                <p>The results from these tests would help users learn about their condition and offer suggestions for potential treatments to look into. This was aided in part by using a friendly, easy-going visual style, as well as a “little-to-no-input” approach on the user’s part.</p>
              </div>
          </div>
              <div className="desktopImages">
                <Img alt="Beddr structure" fluid={props.data.beddr2.childImageSharp.fluid} />
             </div>
             <Caption captiontext="A rough look at the Beddr user journey within the app"/>
             <div className="desktopImages">
                <Img alt="Beddr wireframes" fluid={props.data.beddr10.childImageSharp.fluid} />
             </div>
             <Caption captiontext="Beddr — Assorted wireframes"/>             
             <div className="casestudyContent">
              <div className="CSleftCol">
                <p>The initial onboarding consists of pairing the Sleep Tuner device with the user’s phone, as well as answering a small questionnaire with a variety of questions related to their physical information. These questions will be fundamental for the initial assessment test, as some physiological traits can influence the conducted analysis.</p>
                </div>
            </div>
             <div className="desktopImages">
                <Img alt="Beddr initial questionnaire" fluid={props.data.beddr4.childImageSharp.fluid} />
             </div>
             <Caption captiontext="Beddr — Assorted screens from the initial questionnaire"/>
             <div className="desktopImages">
                <Img alt="Beddr quick-start" fluid={props.data.beddr3.childImageSharp.fluid} />
             </div>
             <Caption captiontext="Beddr — Quick-start guide"/>                
                <div className="casestudyContent">
                <div className="CSleftCol">
                    <p>The general aesthetic is meant to give the app a modern feel. Approachable, and also appropriate for night use. Sometimes, even a bit cheeky.</p>
                    </div>
                <div className="CSrightCol">
                    <p>Indeed, our main objective was to create something friendly, and not condescending or patronising; as well as to avoid being overly dramatic due to apnea being a sensitive topic. We didn’t want to make people feel uncomfortable.</p>
                </div>
            </div>
            <div className="smallerImages">
                <Img alt="Beddr iconography" fluid={props.data.beddr6.childImageSharp.fluid} />
             </div>
             <div className="desktopImages">
                <Img alt="Beddr test" fluid={props.data.beddr7.childImageSharp.fluid} />
             </div>
             <Caption captiontext="Beddr — Testing!"/>                
             <div className="desktopImages">
                <Img alt="Beddr sleep journal" fluid={props.data.beddr5.childImageSharp.fluid} />
             </div>
             <Caption captiontext="Sleep Journal — Assorted questions"/>
             <div className="casestudyContent">
                <div className="CSleftCol">
                    <p>The main screen also contains a supporting, dynamic card that displays different content to the user based on their test results. The objective of this is making the app feel truly tailor-made and relevant to each user’s experience.</p>
                    </div>
                    <div className="CSrightCol">
                    <p>For example, if I mentioned in my sleep journal that I had 3 glasses of wine before going to bed, the card will change to remind me to avoid drinking before going to bed the following night.</p>
                    </div>
                </div>
              <div className="smallerImages">
                <Img alt="Dynamic cards" fluid={props.data.beddr8.childImageSharp.fluid} />
              </div>
             <div className="casestudyContent">
                <div className="CSleftCol">
                    <p>Some of the conversations had with the client dealt with how to present the results a test. Of course, we considered using some graphs, as that can be fairly visually appealing. In the end, we opted for something much more straightforward and direct.</p>
                    </div>
                    <div className="CSrightCol">
                    <p>By that I mean written language, and a small number of graphic elements to support the results instead of charts and graphs — in order to help reducing reading errors and misunderstandings.</p>
                    </div>
                </div>
                <div className="desktopImages">
                <Img alt="Beddr 3d mockup" fluid={props.data.beddr9.childImageSharp.fluid} />
             </div>
             <Caption captiontext="Test results"/>             
              
             <NextProj
              nextproj='Feeln'
              projdesc='Family-friendly streaming.'
              nextlink='/feeln'
            />
          </div>

              
    </Layout>
  )
  
  export default Beddr
  
  
  // Image stuff
    
  export const pageQuery = graphql`
    query {
      beddr1: file(relativePath: { eq: "beddr-01.png" }) {
        ...fluidImage
      }
      beddr2: file(relativePath: { eq: "beddr-02.png" }) {
        ...fluidImage
      }
      beddr3: file(relativePath: { eq: "beddr-03.png" }) {
        ...fluidImage
      }  
      beddr4: file(relativePath: { eq: "beddr-04.png" }) {
        ...fluidImage
      }  
      beddr5: file(relativePath: { eq: "beddr-05.png" }) {
        ...fluidImage
      }  
      beddr6: file(relativePath: { eq: "beddr-06.png" }) {
        ...fluidImage
      }  
      beddr7: file(relativePath: { eq: "beddr-07.png" }) {
        ...fluidImage
      }  
      beddr8: file(relativePath: { eq: "beddr-08.png" }) {
        ...fluidImage
      }  
      beddr9: file(relativePath: { eq: "beddr-09.jpg" }) {
        ...fluidImage
      }  
      beddr10: file(relativePath: { eq: "beddr-10.png" }) {
        ...fluidImage
      }  
    }
  `
  